
import { NgModule } from '@angular/core';
import { ActivateElementDirective } from './activate-element.directive';

@NgModule({
  declarations: [
    ActivateElementDirective
  ],
  exports: [
    ActivateElementDirective
  ]
})
export class ActivateElementModule { }
